:root {
  --dark: rgba(0, 0, 0);
  --trans-dark: rgba(0, 0, 0, 0.89);
  --that-one-color: seashell;
  --default-font-size: 15px;
}

::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
}

a,
a:visited,
a:hover,
a:active {
  color: var(--that-one-color);
}

html,
body {
  height: 100%;
}

body {
  font-size: var(--default-font-size);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

/** STYLES **/

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("./assets/bg@0.3.jpg");
  background-color: grey;
  background-position: center center;
  background-repeat: no-repeat;
}

.interval,
.noteDutation {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.beatIndicator {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.beatIndicator__beat {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 10px;
  background-color: var(--that-one-color);
}

.beatIndicator__beat--active {
  border: 5px solid var(--that-one-color);
}

.barIndicator {
  font-style: italic;
}

.bottomBar {
  width: 100%;
  height: 50px;
  background-color: var(--trans-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: transparent;
  color: var(--that-one-color);
  border: 2px solid var(--that-one-color);
  padding: 5px 10px 5px 10px;
}

.currentNote__sign > strong {
  font-size: 8rem;
  text-align: center;
}

.currentNote__scaleDegree,
.nextNote__scaleDegree {
  text-align: center;
  font-size: 1.5rem;
}

.nextNote__sign {
  font-size: 6rem;
}

.nextNote {
  text-align: center;
}

.randomNote {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.randomNote__body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--that-one-color);
}

.randomNote__footer {
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--that-one-color);
}

.slidyPanel {
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0px;
  transition: left 0.2s;
  z-index: 100;
  background-color: var(--trans-dark);
  box-sizing: border-box;
  padding: 0px 30px 40px 30px;
  color: var(--that-one-color);
}

.slidyPanel__handler {
  width: 50px;
  height: 50px;
  background-color: var(--trans-dark);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  position: absolute;
  right: -50px;
  top: 0px;
  border: 0px;
  color: var(--that-one-color);
}

.slidyPanel__content {
  overflow-y: scroll;
  height: 100%;
}

.slidyPanel__content input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px;
}

.tempoControl,
.volumeControl,
.noteDurationControl {
  display: flex;
}

.tempoControl__input,
.volumeControl__input,
.noteDurationControl__input {
  background-color: transparent;
  color: var(--that-one-color);
  text-align: center;
  border: 0px;
  flex: 1;
}

.tempoControl__progressive,
.tonalMetronome {
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.noteTypeControl {
  margin-top: 5px;
  margin-bottom: 10px;
}

.subdivisionControl,
.noteTypeControl {
  display: flex;
}

.subdivisionControl__subdivision,
.noteTypeControl__noteType {
  flex: 1;
}

.noteControl {
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

.noteControl__note {
  display: block;
  width: 25%;
}

.scalePicker {
  margin-top: 5px;
  margin-bottom: 10px;
}

.scaleTypeSelect {
  margin-left: 5px;
}

.about {
  margin-top: 10px;
  font-size: 0.8rem;
  font-style: italic;
}

.about p {
  margin-top: 5px;
}

@media (min-width: 1352px), (min-height: 900px) {
  #root {
    background-image: url("./assets/bg@0.5.jpg");
  }
}

@media (min-width: 2048px), (min-height: 1363px) {
  #root {
    background-image: url("./assets/bg.jpg");
  }
}
